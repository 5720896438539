/*******************************
     User Global Variables
*******************************/

@pageBackground: #f3f4f5;
@primaryColor   : rgb(240, 88, 37);
@darkColor: #1b1c1d; 
@darkGreen: #002f37;

.backgroundPrimary {
  background: @primaryColor
}

.ui.search > .results{
     border: 0;
     @primaryColor;
}

.ui.search > .results .result:hover,
.ui.category.search > .results .category .result:hover {
  background: @primaryColor;
}

.ui.search > .results,
.ui.category.search > .results .category {
  background: @darkGreen;
}

.ui.divider:not(.vertical):not(.horizontal)  {
  border: solid 1px rgba(240, 88, 37, .4);
  margin: 30px 0px;
}

.ui.search > .results .result {
  border-bottom: 0;
}

.clndr-calendars-segment.clndr-floating {
  top: -304px;  
}

.picker {
    width: 100% !important; 
}